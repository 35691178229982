<template>
  <div class="place-bet">
    <v-row dense>
      <v-col align="center">
        <h1>Your Hand</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" align-self="center" justify-self="center">
        <table class="mx-auto">
          <tr>
            <td
            v-for="(bet, i) in getHand"
            :key="i">
              <game-build-hand-card 
                :id="i"
                :cardValue="bet.value.toString()"
                :cardSuit="bet.suit"
                :which="bet.which"
                :event="bet.event"
                :team="bet.team"
                :sport="bet.sport"
                :bet="bet.bet"
                :showClose="false"
                :isMini="true"
              >
              </game-build-hand-card>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col cols="3" align-self="center">

        <v-row dense no-gutters>
          <v-col cols="6">
            <b>Hand:</b>
          </v-col>
          <v-col cols="6">
            {{getBet.hand_label}}
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col cols="6">
            <b>Parlay:</b>
          </v-col>
          <v-col cols="6">
            {{getBet.parlay_label}}
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="3" align-self="center">

        <v-row dense no-gutters>
          <v-col cols="6">
            <b>Wager:</b>
          </v-col>
          <v-col cols="6">
            ${{addcommas(getBet.wager)}}
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col cols="6">
            <b>To Win:</b>
          </v-col>
          <v-col cols="6">
            ${{addcommas(getBet.to_win)}}
          </v-col>
        </v-row>

      </v-col>
    </v-row>
    <v-spacer class="py-5"></v-spacer>
    <div v-if="getAmount.amount == 0">
      <v-row dense>
        <v-col cols="12" class="text-center">
          <v-alert
            color="primary"
            dark
            class="my-4 mx-5 text-center"
          >
            You currently have no funds in your account to place this bet!
          </v-alert>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <v-btn  x-large color="primary" light to="/dashboard">Go Back</v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="getAmount.amount < getBet.wager">
      <v-row dense>
        <v-col cols="12" class="text-center">
          <v-alert
            color="warning"
            dark
            class="my-4 mx-5 text-center"
          >
            You do not have enough funds in your wallet to place this bet!
          </v-alert>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <v-btn  x-large color="primary" light to="/dashboard">Go Back</v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <v-alert
            color="success"
            dark
            class="my-4 mx-5 text-center"
          >
            Are you ready to place the bet?
          </v-alert>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <v-btn  x-large color="primary" light @click="placebet()">Place Bet</v-btn>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
  import StringsService from "@/services/StringsService.js";
  import ApiService from "@/services/ApiService.js";

  export default {
    name: 'PlaceBet.vue',
    data() {
      return {
        tab: null,
        wager: 1,
      };
    },
    created(){
      if(this.getHandTotal == 0){
        this.$router.replace('/');
      }
    },
    computed:{
      getHand() {
        return this.$store.getters.getHand;
      },
      getHandTotal() {
        return this.$store.getters.getHandTotal;
      },
      getAmount() {
        return this.$store.getters.getAmount;
      },
      getBet() {
        return this.$store.getters.getBet;
      },
      getUserID() {
        return this.$store.getters.getUserID;
      },
    },
    methods: {
      addcommas(val){
        return StringsService.addcommas(val);
      },
      placebet(){
        let self = this;
        let user_id = this.getUserID;
        ApiService
          .post("/bet/create", {
            wager: self.getBet.wager,
            to_win: self.getBet.to_win,
            cards: self.getHand,
            hand_label: self.getBet.hand_label,
            parlay_label: self.getBet.parlay_label,
            user_id: user_id
          })
          .then(() => {
            self.$root.snackbar.message = 'Your bet has been placed!';
            self.$root.snackbar.open = true;
            this.$store.dispatch("resetHand");
            self.$router.replace('/dashboard');
          })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .place-bet{
    table{
      width: 100%;
      table-layout: fixed;
      tr{
        td{
          padding: 10px;
        }
      }
    }
  }
</style>